import { CurrentState } from '@gth-legacy/components/event-info/state/state';
import { createAction, props } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import firebase from 'firebase/compat/app';

import { AuthResults } from '../../../../../../gth-legacy/src/lib/services';

export enum AuthActionTypes {
    AuthInit = '[Auth] Init',
    AuthLoad = '[Auth] Auth Load',
    AuthLoadSuccess = '[Auth] Auth Load Success',
    AuthLoadError = '[Auth] Auth Load Error',

    AuthLogout = '[Auth] Auth Logout',
    AuthLogoutSuccess = '[Auth] Auth Logout Success',
    AuthLogoutError = '[Auth] Auth Logout Error',
    AuthUpdate = '[Auth] Update',

    AuthGoogleLogin = '[Auth] Auth Google Login',
    AuthGoogleLoginSuccess = '[Auth] Auth Google Login Success',
    AuthGoogleLoginError = '[Auth] Auth Google Login Error',

    AuthEmailLogin = '[Auth] Auth Email Login',
    AuthEmailLoginSuccess = '[Auth] Auth Email Login Success',
    AuthEmailLoginError = '[Auth] Auth Email Login Error',

    AuthProviderLogin = '[Auth] Auth Login',
    AuthProviderLoginSuccess = '[Auth] Auth Login Success',
    AuthProviderLoginError = '[Auth] Auth Login Error',

    AuthWatchOnlineStatus = '[Auth] Watch Online Status',
    AuthUpdateOnlineStatus = '[Auth] Update Online Status',
}

export const AuthInit = createAction(
  AuthActionTypes.AuthInit,
);
export const AuthLoad = createAction(
    AuthActionTypes.AuthLoad,
);
export const AuthUpdate = createAction(
  AuthActionTypes.AuthUpdate,
);
export const AuthSuccess = createAction(
    AuthActionTypes.AuthLoadSuccess,
    props<{ user: GthUserModel, state: CurrentState }>(),
);
export const AuthError = createAction(
  AuthActionTypes.AuthLoadError,
  props<{ error: unknown }>(),
);
export const AuthLogout = createAction(
  AuthActionTypes.AuthLogout,
);
export const AuthLogoutSuccess = createAction(
  AuthActionTypes.AuthLogoutSuccess,
);
export const AuthLogoutError = createAction(
  AuthActionTypes.AuthLogoutError,
  props<{ error: unknown }>(),
);

export const AuthGoogleLogin = createAction(
  AuthActionTypes.AuthGoogleLogin,
);
export const AuthGoogleLoginSuccess = createAction(
  AuthActionTypes.AuthGoogleLoginSuccess,
  props<{ user: GthUserModel, login: AuthResults }>(),
);
export const AuthGoogleLoginError = createAction(
  AuthActionTypes.AuthGoogleLoginError,
  props<{ error: unknown, login: AuthResults }>(),
);

export const AuthEmailLogin = createAction(
  AuthActionTypes.AuthEmailLogin,
  props<{ email: string, password: string }>(),
);
export const AuthEmailLoginSuccess = createAction(
  AuthActionTypes.AuthEmailLoginSuccess,
  props<{ user: GthUserModel, login: AuthResults }>(),
);
export const AuthEmailLoginError = createAction(
  AuthActionTypes.AuthEmailLoginError,
  props<{ error: unknown, login: AuthResults }>(),
);

export const AuthProviderLogin = createAction(
  AuthActionTypes.AuthProviderLogin,
  props<{ provider: firebase.auth.AuthProvider }>(),
);
export const AuthProviderLoginSuccess = createAction(
  AuthActionTypes.AuthProviderLoginSuccess,
  props<{ user: GthUserModel, login: AuthResults }>(),
);
export const AuthProviderLoginError = createAction(
  AuthActionTypes.AuthProviderLoginError,
  props<{ error: unknown, login: AuthResults }>(),
);

export const AuthWatchOnlineStatus = createAction(
  AuthActionTypes.AuthWatchOnlineStatus,
);

export const AuthUpdateOnlineStatus = createAction(
  AuthActionTypes.AuthUpdateOnlineStatus,
  props<{ isOnline: boolean }>(),
);
